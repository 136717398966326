<template>
  <div class="h-screen overflow-hidden">
    <div class="py-32 px-48 h-screen overflow-y-auto">
      <div class="mb-24">
        <logo class="h-[40px]" data-test-id="selliaLandingLogo" />
      </div>

      <Toast ref="toast" @refreshPage="refreshPage" />
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { RouterView } from 'vue-router';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import Toast from '@/components/Toast.vue';
import Logo from '@/views/Logo.vue';

const toast = ref(null);
const refreshPage = () => {
  updateServiceWorker();
};

const { updateServiceWorker } = useRegisterSW({
  onNeedRefresh() {
    toast.value.showToastWithCounter();
  },
});
</script>

<style lang="css">
@import '~styles/tailwind.css';
</style>
